<script>
    import { htmlLogo } from '../components/utils';
</script>

<div>
    <p>{@html htmlLogo} est une aide au calcul des frais professionels sous licence GPLv3.0</p>
    <p><strong>Les PN restents seuls responsables face à l’administration pour justifier l’exactitude
        de leur déclaration</strong>
    </p>
</div>

<style>
    div {
        font-size: small;
        margin-top: 50px;
    }
    p {
        margin-block-end: 0;
        margin-block-start: 0;
    }
</style>